import proptypes from 'proptypes';
import React from 'react';
import '../styles/site.scss';
import Footer from './Footer';
import GdprPopup from './GdprPopup';
import Navigation from './Navigation';
import SEO from './SEO';

const TemplateWrapper = ({ children }) => (
    <div id="content-wrapper">
        <SEO location="" />
        <Navigation />
        <main className="content">{children}</main>
        <Footer />
        <GdprPopup />
    </div>
);

export default TemplateWrapper;

TemplateWrapper.propTypes = {
    children: proptypes.any,
};
