import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

const LinkProvider = ({ wrapperClass }) => {
    const navItems = useStaticQuery(graphql`
        {
            settingsYaml {
                nav_items {
                    label
                    path
                }
            }
        }
    `);

    return navItems.settingsYaml.nav_items.map((navItem, index) => (
        <Link to={navItem.path} key={index}>
            <span className={wrapperClass}>{navItem.label}</span>
        </Link>
    ));
};

export default LinkProvider;
