/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react';
import NavigationProvider from '../NavigationProvider';
import Logo from '../Logo';
import './Navigation.scss';

const Button = (params) => (
    <div className={`wrapper ${params.cRole}`}>
        <button
            role="button"
            className="trigger"
            aria-label={`${params.cRole} menu`}
            onClick={() => params.handleClick()}
        >
            <span className={`icon ${params.cRole}`} />
        </button>
    </div>
);

const Navigation = () => {
    const [isOpen, toggleOpen] = useState(false);

    return (
        <>
            <Logo id="logo" />
            <div className={`navbar ${isOpen ? 'active' : ''}`} id="navbar">
                <Button cRole="close" handleClick={() => toggleOpen(!isOpen)} />

                <div className="menu">
                    <NavigationProvider wrapperClass="claim" toggleOpen={toggleOpen} />
                </div>

                <Button cRole="open" handleClick={() => toggleOpen(!isOpen)} />
            </div>
        </>
    );
};
export default Navigation;
