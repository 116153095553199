import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';

const LinkProvider = (params) => {
    const navItems = useStaticQuery(graphql`
        {
            settingsYaml {
                nav_items {
                    label
                    path
                }
            }
        }
    `);

    const redirect = (e, target) => {
        e.preventDefault();
        params.toggleOpen(false);
        setTimeout(() => {
            navigate(target);
        }, 250);
    };

    return (
        <ul className="wrapper full-height">
            {navItems.settingsYaml.nav_items.map((navItem, index) => (
                <li key={index}>
                    <a
                        onClick={(e) => redirect(e, navItem.path)}
                        href={navItem.path}
                        title={`to ${navItem.label}`}
                    >
                        <span className={params.wrapperClass}>{navItem.label}</span>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default LinkProvider;
