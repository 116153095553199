/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const SEO = ({ children, description, title, image }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);

    return (
        <Helmet titleTemplate={title ? `%s | ${site.siteMetadata.title}` : site.siteMetadata.title}>
            <html lang="en" />
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description || site.siteMetadata.description} />
            {/* Favicons */}
            <link rel="icon" type="image/svg+xml" href="img/favicon.png" />
            {/*             <link rel="icon" type="image/svg+xml" href="img/favicon-96x96.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="img/apple-icon-180x180.png" />
            <link rel="icon" type="image/png" href="img/favicon-32x32.png" sizes="32x32" /> */}
            {/*  Metas */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:sitename" content={site.siteMetadata.title} />
            <meta
                property="og:description"
                content={description || site.siteMetadata.description}
            />
            <meta property="og:image" content={image || 'img/logo.png'} />
            <meta name="theme-color" content="#fff" />
            {children}
        </Helmet>
    );
};

export default SEO;
