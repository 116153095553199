import { Link } from 'gatsby';
import React from 'react';
import LinkProvider from '../LinkProvider';
import Logo from '../Logo';
import './Footer.scss';

const Footer = () => {
    const currentDate = new Date();

    return (
        <footer id="footer" className="">
            <div className="container">
                <nav className="wrapper">
                    <LinkProvider />
                    <Link to="/legal">
                        <span>legal</span>
                    </Link>
                    <Link to="/privacy-policy">
                        <span>privacy policy</span>
                    </Link>
                </nav>
                <Logo />
                <span className="disclaimer">
                    © {`2019- ${currentDate.getFullYear()} `}Castrum Doloris Design. All rights
                    reserved.
                </span>
            </div>
        </footer>
    );
};

export default Footer;
