import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './Gdpr-popup.scss';

const GdprPopup = () => {
    const [active, setActive] = useState(false);

    const setCookie = () => {
        Cookies.set('GDPR', true, { expires: 365 });
        setActive(false);
    };
    const evaluateCookie = () => {
        const DGSVO = Cookies.get('GDPR');

        if (!DGSVO) {
            setActive(true);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            evaluateCookie();
        }, 2000);
    }, []);

    return (
        <div id="GDPR" className={active ? 'active' : ''}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <p>We believe in freedom an privacy. That's why we won't track you here.</p>
                    <p>We only set a single cookie to keep this banner closed once you close it.</p>
                    <button type="button" className="accept" onClick={setCookie}>
                        ok
                    </button>
                    <button type="button" className="close" onClick={setCookie}>
                        x
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GdprPopup;
